
/*
-----------------------------------------------------------

  Navigation

  Uses Vue router if available. Falls back to window.location.

-----------------------------------------------------------
*/

const formatPath = (path) => {

  X(path)

  let pathFormatted = ''

  if(!path){
    pathFormatted = path
  }
  else if(path.indexOf('/') == 0){
    pathFormatted = path
  }
  else{
    pathFormatted = `/${path}`
  }

  // X(pathFormatted)

  return pathFormatted

}

export default {

  to(path, router = null){

    const pathFormatted = formatPath(path)

    // X('HORSE')
    // if(typeof this.router != 'undefined' && this.router)
    //   X(router)
    // if(typeof this.$router != 'undefined' && this.$router)
    //   X(this.$router)
    // if(typeof this.$nuxt != 'undefined' && this.$nuxt)
    //   X(this.$nuxt)
    // X('END HORSE')

    // return

    // Vue Router
    if(router){
      router.push(pathFormatted)
    }
    // Nuxt
    else if(this.$router){
      this.$router.push({
        path: pathFormatted
      })
    }
    // Regular Browser API
    else if(window){
      // X('no router')
      window.location = pathFormatted
    }

  }

}
