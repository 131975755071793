
/*
===========================================================

  Main Vuex Module

  This Vuex module is the primary way to install the 
  Creek Studio Website Toolkit for Vue.

===========================================================
*/

/*
-----------------------------------------------------------
  Modules
-----------------------------------------------------------
*/

import GlobalPlayerModule from './GlobalPlayerModule'
import StudioModule from './StudioModule'

/*
-----------------------------------------------------------
  Export
-----------------------------------------------------------
*/

export default {

  namespaced: true,

  modules: {
    globalPlayer: GlobalPlayerModule,
    studio: StudioModule,
  },

}
