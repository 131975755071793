/*
===========================================================
  
  Creek Studio: Studio Module

===========================================================
*/

/*
-----------------------------------------------------------
  NPM
-----------------------------------------------------------
*/

import axios from 'axios'
import _, { capitalize } from 'lodash'

/*
-----------------------------------------------------------
  Shortcuts
-----------------------------------------------------------
*/

const X = console.log

/*
-----------------------------------------------------------
  Helper Functions
-----------------------------------------------------------
*/

const handleError = (error) => {
    
  errorMessage = error.message || error
  X('Error: ' + errorMessage)

  // TODO: Log to Sentry.

}

const handleAxiosResponseError = (axiosError) => {
  
  // TODO: Log to Sentry.
  
  // console.log(axiosError)

  // Get error from data if available.
  if(_.get(axiosError, 'response.data.error')){
    console.log('Creek: Error:')
    console.log(axiosError.response.data.error)
    Creek.response.error = axiosError.response.data.error
  }

  if(_.get(axiosError, 'response.data.errors')){
    console.log('Creek: Errors:')
    console.log(axiosError.response.data.errors)
    Creek.response.errors = axiosError.response.data.errors
  }

  return null

}

const responseHandler = async (axiosRequest) => {
  
  let response = null

  try{
    response = await axiosRequest
  }catch(responseError){
    // console.log(responseError)
    // console.log(responseError.response)
    return handleAxiosResponseError(responseError)
  }

  // Handle 200 response with error in its JSON.
  if(response.data && response.data.error){
    handleError(response.data.error)
    return null
  }

  // Handle responses both with and without data property.
  if(response.data && response.data.data){
    return response.data.data
  }else if(response.data){
    return response.data
  }

}

/*
-----------------------------------------------------------
  Store
-----------------------------------------------------------
*/

export default {

  namespaced: true,

  state () {
    return {

      domain: null,
      
      studio: null,
      
      studioName: null,

      current: {},

      streams: [],

      times: [],

      shows: [],

      show: null,
      
      broadcasts: [],
      
      broadcast: null,

      recentBroadcasts: [],

      streamPrimary: null,

      UI: {
        isChatToolbarOpen: false,
        isVideoToolbarOpen: false,
        broadcastToolbarHeight: 0,
        isWebAppInstructionsBoxVisible: false,
      },

      showsStringToFind: '',

      scheduleWeekdayNumber: null,

      showsPath: '/shows',

      scheduleViewSelected: 'calendar',
      // scheduleViewSelected: 'schedule',

    }
  },

  mutations: {

    setDomain: (state, payload) => {
      state.domain = payload
    },

    // Set Main Objects
    setStudio: (state, payload) => {
      state.studio = payload
    },
    setCurrent: (state, payload) => {
      state.current = payload
    },
    setStreams: (state, payload) => {
      
      state.streams = payload

      // X('streams:')
      // X(payload)

      const streams = payload
      let streamPrimary = null

      if(!streams){
        return null
      }

      // Use Stream where stream.purpose == 'main'
      for(let stream of streams){
        if(stream.purpose == 'main'){
          state.streamPrimary = stream
          // X('primary stream: found main stream:')
          // X(stream)
        }else{
          // X('not main stream:')
          // X(stream)
        }
      }

      // Default to the first one.
      if(!state.streamPrimary){
        // X('primary stream: defaulting to [0]')
        state.streamPrimary = streams[0]
      }

    },
    setRecentBroadcasts: (state, payload) => {
      state.recentBroadcasts = payload
    },

    toggleChatToolbar: (state) => {
      state.isChatToolbarOpen = !state.UI.isChatToolbarOpen
    },

    openChatToolbar: (state) => {
      state.UI.isChatToolbarOpen = true
    },

    closeChatToolbar: (state) => {
      state.UI.isChatToolbarOpen = false
    },

    toggleVideoToolbar: (state) => {
      state.UI.isVideoToolbarOpen = !state.UI.isVideoToolbarOpen
    },

    toggleWebAppInstructionsBox: (state) => {
      state.UI.isWebAppInstructionsBoxVisible = !state.UI.isWebAppInstructionsBoxVisible
    },

    setShowsStringToFind: (state, payload) => {
      state.showsStringToFind = payload
    },

    setScheduleWeekdayNumber: (state, payload) => {
      X(payload)
      state.scheduleWeekdayNumber = payload
    },

    setShowsPath: (state, payload) => {
      X(payload)
      state.showsPath = payload
    },

    setAuthorsPath: (state, payload) => {
      X(payload)
      state.authorsPath = payload
    },

    selectScheduleView: (state, payload) => {
      X(payload)
      state.scheduleViewSelected = payload
    },

  },

  actions: {

    async init({ rootState, state, commit, dispatch }, studioNameOrDomain){
      
      // X(studioNameOrDomain)

      let domain = null

      if(studioNameOrDomain.includes('.')){
        domain = studioNameOrDomain
        state.studioName = studioNameOrDomain.split('.')[0]
      }else{
        domain = studioNameOrDomain + '.studio.creek.org'
        state.studioName = studioNameOrDomain
      }

      state.domain = domain

      commit('setDomain', domain)

      dispatch('getStudio')
      
      await dispatch('getStreams')

      commit('creek/globalPlayer/activateStream', state.streamPrimary, { root: true })

    },

    // Get resources from the Creek Studio API.
    async getResource(

      { state, commit }, 

      {

        // Path of the resource, including query strings, etc.
        path = null,

        // ID of resource, if needed.
        id = null,
        
        // Nuxt Workaround: Use existing axios instance.
        axiosInstanceProvided = null,
      
        // Commit the data from the response to the state at name.
        shouldCommit = true,
        
        // Optional: Explicit name of Vuex commit function. 
        // Else it defaults to: `set{name}`
        commitFunctionName = null,

      }

    ){

      // X('Getting path:')
      // X(path)

      if(!state.domain){
        throw Error('Creek: No domain set.')
      }

      if(!path){
        throw Error('Creek: No resource path specified.')
      }

      // Get resource name from path. 
      // These should always map directly from 
      // the Creek Studio API to this Vuex data store.
      let name = path.split('?')[0].split('/')[0]

      if(id){
        path += '/' + id
      }
      
      let URLPrefix = `https://${state.domain}/`

      let axiosInstance = null

      // For Nuxt: Use provided axios instance if available.
      if(axiosInstanceProvided){

        axiosInstance = axiosInstanceProvided
      
      }else{

        // Use API path as default baseURL.
        axiosInstance = axios.create({
          baseURL: URLPrefix + 'api/',
          timeout: 10000,
        })

      }

      const result = await responseHandler(
        axiosInstance.get(path)
      )

      if(shouldCommit){
        if(commitFunctionName){
          commit(commitFunctionName, result)
        }else{
          commit(`set${_.capitalize(name)}`, result)
        }
      }

      // X('Result:')
      // X(result)

      return result

    },

    async getStudio({ commit, dispatch }){
      commit(
        'setStudio',
        await dispatch('getResource', { path: 'studio' })
      )
    },
    
    async getCurrent({ commit, dispatch }){
      commit(
        'setCurrent', 
        await dispatch('getResource', { path: 'current' })
      )
    },

    async getStreams({ commit, dispatch }){
      const streams = await dispatch('getResource', { path: 'streams' })
      // X('woooooaosdoasdasodoas')
      // X(streams)
      commit(
        'setStreams',
        streams
      )
    },

    async getTimes({ commit, dispatch }){
      commit(
        'setTimes', 
        await dispatch('getResource', { path: 'times' })
      )
    },

    async getRecentBroadcasts({ state, commit, dispatch }){
      commit(
        'setRecentBroadcasts', 
        await dispatch('getResource', { 
          path: 'broadcasts',
          resourcePath: 'broadcasts/not-expired',
        })
      )
    },

  }

}
