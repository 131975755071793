<template>
  <div class="creek-calendar">
    <div class="week">
      <div class="hours">
        <div class="hours-header">&nbsp;</div>
        <div 
          v-for="hour in getHours()" 
          class="hour" 
          v-text="hour.title"
          :style="{ height: getHourHeight() }"
        ></div>
      </div>
      <div class="weekday-column" v-for="w in [1,2,3,4,5,6,7]">
        <div class="weekday-header" v-text="getISOWeekday(w)"></div>
        <div 
          class="block"
          :style="getBlockStyles(block)"
          v-for="block in blocks[w]" 
        >
          <div
            v-if="block.isGap"
            class="block-inner gap"
          >
            &nbsp;
            <!--
            [gap]
            <div v-text="block.startDate"></div>
            <div v-text="block.endDate"></div>
            <div v-text="block.duration"></div>
            <div v-text="block.prevTitle"></div>
            <div v-text="block.nextTitle"></div>
            -->
          </div>
          <div
            class="block-inner"
            v-if="!block.isGap"
          >
            <div 
              class="time" 
              v-html="getTimespan(block)"
            ></div>
            <!--
            <div v-text="block.startSeconds"></div>
            <div v-text="block.endUnix"></div>
            <div v-text="block.startDate"></div>
            -->
            <div class="show">
              <a 
                class="show-title" 
                @click="navigateTo(getShowPath(block.time.show))" 
                v-text="block.time.show.title"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import _orderBy from 'lodash.orderby'

import {
  lightFormat,
  parseISO,
  differenceInSeconds,
  startOfDay,
} from 'date-fns'

import navigate from '@creek-website-toolkit/lib/navigate.js'
import schedule from '@creek-website-toolkit/lib/schedule.js'

export default {
  name: 'CreekCalendar',
  data(){
    return {
      heightDivider: 30
    }
  },
  props: {
    occurrences: {
      type: Array,
      default: [],
    }
  },
  computed: {
    blocks(){

      let blocks = []

      this.occurrencesCloned = JSON.parse(JSON.stringify(this.occurrences))

      // Combine blocks
      for(let o of this.occurrencesCloned){

        if(!blocks[o.localWeekdayNum]){
          blocks[o.localWeekdayNum] = []
        }

        // o.startUnix = parseISO(o.startDate).getTime() / 1000
        // o.endUnix = parseISO(o.endDate).getTime() / 1000

        // X('date1:')
        // X(o.startDate)

        o.startDate = parseISO(o.startDate)
        o.endDate = parseISO(o.endDate)

        o.startSeconds = differenceInSeconds(
          o.startDate, startOfDay(o.startDate)
        )
        o.endSeconds = differenceInSeconds(
          o.endDate, startOfDay(o.startDate)
        )

        o.duration = o.time.duration

        blocks[o.localWeekdayNum].push(o)

      }
      
      X('blocks1:')
      X(blocks)


      // // HACK: Sort by alphabetical time string.
      // function compare( a, b ) {
      //   if ( a.startSeconds < b.startSeconds ){
      //     return -1;
      //   }
      //   if ( a.startSeconds > b.startSeconds ){
      //     return 1;
      //   }
      //   return 0;
      // }

      // Sort by start time.
      for(let w = 1; w <= 7; w++){
        X('sorting weekday: ' + w)
        if(blocks[w]){
          blocks[w].sort((a,b) => a.startSeconds - b.startSeconds)
          let yep = ''
          for(let t = 0; t < blocks[w].length; t++){
            yep += ' ' + blocks[w][t].startSeconds
          }
          X(yep)
        }
      }

      // Add gaps.
      for(let w = 1; w <= 7; w++){

        if(!blocks[w]){
          continue
        }

        for(let t = 0; t < blocks[w].length; t++){

          // if(!blocks[w][t - 1] || !blocks[w][t - 1].startSeconds){
          //   continue
          // }

          let prevEndSeconds = 0
          let prevEndDate = null

          let nextStartDate = blocks[w][t].startDate
          let nextStartSeconds = blocks[w][t].startSeconds
          let nextEndSeconds = blocks[w][t].endSeconds
          

          // Add placeholder values if this is the first block.
          if(t == 0){
            
            X('first block')
            prevEndSeconds = 0
            prevEndDate = startOfDay(nextStartDate)

          }else{

            prevEndSeconds = blocks[w][t - 1].endSeconds
            prevEndDate = blocks[w][t - 1].endDate
          
          }


          X(`w:${w} t:${t} ${nextStartDate}`)

          // Add a gap only if...
          if(

            // It's not the last block for this weekday
            blocks[w][t + 1]

            // And it doesn't end at the start time of the next block
            && prevEndSeconds != nextStartSeconds

            // And it's not an overlapping block
            && prevEndSeconds != nextEndSeconds

            // And isn't already a gap
            && !blocks[w][t].isGap

            // And isn't already a gap
            && (!blocks[w][t - 1] || !blocks[w][t - 1].isGap)

          ){

            let gapDuration = nextStartSeconds - prevEndSeconds

            let gap = {
              isGap: true,
              startDate: prevEndDate,
              endDate: nextStartDate,
              startSeconds: prevEndSeconds,
              endSeconds: nextStartSeconds,
              prevEndSeconds: nextStartSeconds,
              duration: gapDuration,
            }



            // if(blocks[w][t].time){
            //   gap.prevTitle = blocks[w][t].time.show.title
            // }
            // if(blocks[w][t + 1].time){
            //   gap.nextTitle = blocks[w][t + 1].time.show.title
            // }


            X(gap)
            
            if(t == 0){
              blocks[w].splice(t, 0, gap)
            }else{
              blocks[w].splice(t + 1, 0, gap)
            }

          }
        }
      }

      for(let w = 1; w <= 7; w++){
        X('sorting weekday: ' + w)
        if(blocks[w]){
          blocks[w].sort((a,b) => a.startSeconds - b.startSeconds)
          let yep = ''
          for(let t = 0; t < blocks[w].length; t++){
            yep += ' ' + blocks[w][t].startSeconds
          }
        }
      }

      X(blocks)

      return blocks
    }
  },
  methods: {
    getHourHeight(){
      return 3600 / this.heightDivider + 'px'
    },
    getTimespan(block){
      let startDate = block.startDate
      let endDate = block.endDate
      
      if(!block.startDate || !block.endDate){
        return null
      }
      
      // X(block.startDate)
      // X(startDate)
      // X(startDate)
      // X(endDate)

      if(typeof block.startDate != 'object'){
        startDate = parseISO(block.startDate)
      }

      if(typeof block.endDate != 'object'){
        endDate = parseISO(block.endDate)
      }

      const timeString = lightFormat(startDate, 'h:mmaaaaa') 
      + '&ndash;' 
      + lightFormat(endDate, 'h:mmaaaaa')

      return timeString
    },
    getHours(){
      let hours = []
      hours.push({
        title: '12:00AM'
      })
      for(let i = 1; i <= 12; i++){
        hours.push({
          title: `${i}:00AM`
        })
      }
      for(let i = 1; i <= 12; i++){
        hours.push({
          title: `${i}:00PM`
        })
      }
      return hours
    },
    getBlockStyles(block){

      // Font Size
      let size = 12 + block.duration / 3600 * 3
      if(size > 20){
        size = 20
      } 
      let fontSize = size + 'px'

      // Height
      let h = block.duration / this.heightDivider
      let height = h + 'px'

      // Return the styles.
      return { 
        height,
        fontSize,
      }
    },
    getShowPath(show){
      return this.$store.state.creek.studio.showsPath + '/' + show.name
    },
    getAuthorPath(author){
      return this.$store.state.creek.studio.authorPath + '/' + author.name
    },
    getISOWeekday: schedule.getISOWeekday,
    navigateTo: navigate.to,
  }
}
</script>
