import { 
  defineCustomElement as VueDefineCustomElement, 
  h, 
  createApp, 
  getCurrentInstance 
} from 'vue'
import createStoreWithStudioModule from './store'

const defineCustomElementWithStore = (component) => {
  return VueDefineCustomElement({
    render: () => h(component),
    props: () => { },
    setup() {

      const app = createApp()
      
      const store = createStoreWithStudioModule()

      app.use(store)
            
      const inst = getCurrentInstance()
      
      console.log(inst)
      console.log(app)
      
      // Object.assign(inst._props, { woop: 1 })
      Object.assign(inst.appContext, app._context)
      Object.assign(inst.provides, app._context.provides)
      
      console.log(inst)

    },
  })
}

export default defineCustomElementWithStore
