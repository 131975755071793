/*
===========================================================
  
  Creek Website Toolkit: Elements

===========================================================
*/

/*
-----------------------------------------------------------
  Modules
-----------------------------------------------------------
*/

// NPM
import { defineCustomElement } from 'vue'

// Local
import CreekScheduleElement from './elements/CreekScheduleElement.ce.vue'
import CreekPlaylistsElement from './elements/CreekPlaylistsElement.ce.vue'

// Store
import defineCustomElementWithStore from './lib/defineCustomElementWithStore'
import createStoreWithStudioModule from './lib/store'

/*
-----------------------------------------------------------
  Shortcuts
-----------------------------------------------------------
*/

window.X = console.log

/*
-----------------------------------------------------------
  Development
-----------------------------------------------------------
*/

// Dev
import { createApp } from 'vue'
import CreekSchedule from '@creek-website-toolkit/components/schedule/Schedule.vue'
const app = createApp(CreekSchedule)
const store = createStoreWithStudioModule()
// store.dispatch('creek/studio/init', 'loudspeaker.studio.creek.org')
store.dispatch('creek/studio/init', 'kwmr.dev-studio.creek.org')
// store.dispatch('creek/studio/init', 'ashevillefm.dev-studio.creek.org')
app.use(store)
app.mount('#app')


/*
-----------------------------------------------------------
  Elements
-----------------------------------------------------------
*/

const defineElements = () => {

  /*
  -----------------------------------------------------------
    Prevent double-loading the toolkit.
  -----------------------------------------------------------
  */

  if(window.$Creek){
    return
  }else{
    window.$Creek = {
      isLoaded: true
    }
  }

  /*
  -----------------------------------------------------------
    Elements
  -----------------------------------------------------------
  */

  // Show Schedule
  customElements.define(
    'creek-show-schedule', 
    defineCustomElementWithStore(CreekScheduleElement)
  )

  // Playlist Browser
  customElements.define(
    'creek-playlists', 
    defineCustomElement(CreekPlaylistsElement)
  )

  /*

  // Audio Player
  customElements.define(
    'creek-audio-player', 
    defineCustomElementWithStore(CreekAudioPlayer)
  )

  // Control buttons (Play/Stop/Pause/Forward/Back) and seek bar (if needed).
  // Plays the main stream by default.
  customElements.define(
    'creek-player-controls', 
    defineCustomElementWithStore(CreekPlayerControl)
  )

  // Current show, song, etc.
  customElements.define(
    'creek-current', 
    defineCustomElementWithStore(CreekCurrentElement)
  )

  // Omni-Schedule: Includes Events and One-Time Shows
  customElements.define(
    'creek-omni-schedule', 
    defineCustomElementWithStore(CreekScheduleElement)
  )
  
  */

}

/*
-----------------------------------------------------------
  Initialize
-----------------------------------------------------------
*/

defineElements()
