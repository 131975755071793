<template>
  <div class="creek-find">
    <input 
      v-model="stringToFind"
      type="text" 
      placeholder="Find..."
    />
  </div>
</template>

<script type="text/javascript">

export default {
  name: 'CreekFindTool',
  data () {
    return {

    }
  },
  computed: {
    stringToFind: {
      get () {
        return this.$store.state.creek.studio.showsStringToFind
      },
      set (value) {
        this.$store.commit('creek/studio/setShowsStringToFind', value)
      }
    }
  },
  methods: {

  },
  async created () {

  },
}

</script>
