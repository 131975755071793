<template>
  <div 
    class="creek-schedule-nav"
  >
    <ul class="creek-schedule-nav-weekdays">
      <li 
        v-for="(weekday, weekdayNum) in weekdays" 
        class="creek-schedule-nav-weekday"
      >
        <button 
          class="creek-schedule-nav-weekday-button"
          :class="{ 'creek-selected': weekdayNum == weekdayNumber }"
          :to="'?weekday=' + weekdayNum" 
          v-text="weekday"
          @click="setScheduleWeekdayNumber(weekdayNum)"
        />
      </li>
    </ul>
    <!--
    <div 
      class="creek-schedule-nav-all"
      :class="{ 'creek-selected': pageName == 'all' }"
    >
      <router-link 
        to="/shows" 
        v-text="lang.schedule.all" 
      />
    </div>
    -->
  </div>
</template>

<script type="text/javascript">

import lang from '@creek-website-toolkit/lang/eng'

export default {

  name: 'CreekScheduleNav',

  props: {

    pageName: {
      type: String,
      default: null,
    },

  },

  components: {
  },

  data () {

    return {

      weekdays: lang.schedule.weekdays,

      lang,

    }

  },

  computed: {
    weekdayNumber(){
      return this.$store.state.creek.studio.scheduleWeekdayNumber
    }
  },
  methods: {
    setScheduleWeekdayNumber(payload){
      this.$store.commit('creek/studio/setScheduleWeekdayNumber', payload)
    }
  },
  created () {

  },
}

</script>
