<template>
<div 
  class="creek-track" 
  :class="{ 'can-open': !alwaysShowExtra && (track.song.album || track.song.label) }"
>
  <div class="track-inner" @click="toggleOpen">
    <div class="time" v-html="formatTime(track.start)"></div>
    <div class="info">
      <!--
      -->
      <div 
        class="extra-info-available" 
        v-if="!isOpen && !alwaysShowExtra && (track.song.album || track.song.label)"
      >
        ...
      </div>
      <div class="title" v-html="track.song.title"></div>
      <div class="artist" v-html="track.song.artist"></div>
      <div 
        class="extra-info" 
        v-if="(isOpen || alwaysShowExtra) && (track.song.album || track.song.label)"
      >
        <div class="album">
          <b>Album: </b>
          <span v-text="track.song.album"></span>
        </div>
        <div class="spacer">&nbsp;</div>
        <div class="label" v-if="track.song.label">
          <b>Label: </b>
          <span v-text="track.song.label"></span>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { format, parse } from 'fecha'
export default {
  name: 'CreekTrack',
  props: {
    track: {
      type: Object
    },
    alwaysShowExtra: {
      type: Boolean
    },
  },
  data(){
    return {
      isOpen: false
    }
  },
  methods: {
    toggleOpen(){
      this.isOpen = !this.isOpen
    },
    formatTime(date = null){
      return format(new Date(date), 'hh:mma')
    },
  }
}
</script>