<template>
  <div class="creek-playlists-element">
    <div class="creek-playlists-element-header">
      <slot name="header"></slot>
    </div>
    <CreekPlaylistsPanel
      :studioName="studioName"
      :showName="showName"
    ></CreekPlaylistsPanel>
    <div class="creek-playlists-element-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>


<script>

import CreekPlaylistsPanel from '@creek-website-toolkit/components/panels/PlaylistsPanel.vue'

export default {
  name: 'CreekPlaylistsElement',
  components: {
    CreekPlaylistsPanel
  },
  props: {
    studioName:{
      type: String,
      default: null,
      // default: 'kalw'
    },
    showName:{
      type: String,
      default: null,
      // default: 'wonway-posibul',
    },
  },
  created(){
    
    window.$Creek = {
      domain: this.studioName + '.studio.creek.org'
    }
    
    console.log('Creek: Loading: ' + this.studioName)

    if(!this.studioName){
      throw new Error('Creek: No studio-name specified in tag.')
    }

  }
  // styles: ['div.hello { background: #f00 }']
}
</script>

<style lang="sass">

body
  // background: #fff
  // color: #fff
  font-family: sans-serif, sans

#app
  font: 14px 'Helvetica', sans-serif, sans !important


@import "@creek-website-toolkit/sass/playlists-panel.sass"

</style>
