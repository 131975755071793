/*

Handle "audioElement.type = 'audio/mpeg'" by using stream codec or media codec.

Add custom link element for handling the "n-link" thing. It's better if the titles in the show blocks are links, rather than handling it with the "click" event watcher.

Test this in iOS to make sure that the notification controls work.

Only do the cache-buster thing on Firefox. It breaks iOS notification.





*/


const X = console.log


let audioElement = null

if(typeof document != 'undefined' && !audioElement){

  X('creating audioElement')

  audioElement = document.createElement('audio')

  audioElement.id       = 'creek-audio-element'
  audioElement.controls = 'controls'

  // audioElement.type     = 'audio/mpeg'

  document.body.prepend(audioElement)

  audioElement.style.display = 'none'

}

/*


setTimeout(() => {
  // audioElement.src = 
  // audioElement.load()
  // audioElement.play()
}, 3000)


let playButton = document.createElement('button')
playButton.id = 'creek-play-button'
playButton.type = 'button'

playButton.appendChild(document.createTextNode("Play"))

document.body.prepend(playButton)


playButton.addEventListener('click', event => {
  X('play clicked')
  X(event)
  if(!isPlaying){
    X('playing!')
    audioElement.play()
  }else{
    X('pausing!')
    audioElement.pause()
  }
})

*/

const startNotificationMetadata = () => {

  if (!navigator.mediaSession) {
    return
  }

  // alert(3)
  navigator.mediaSession.metadata = new MediaMetadata({
    title: 'ATX Soul Radio',
    artist: 'ATX Soul Radio.com',
    album: 'ATX Soul Radio.',
    artwork: [
      { src: 'https://dummyimage.com/96x96',   sizes: '96x96',   type: 'image/png' },
      { src: 'https://dummyimage.com/128x128', sizes: '128x128', type: 'image/png' },
      { src: 'https://dummyimage.com/192x192', sizes: '192x192', type: 'image/png' },
      { src: 'https://dummyimage.com/256x256', sizes: '256x256', type: 'image/png' },
      { src: 'https://dummyimage.com/384x384', sizes: '384x384', type: 'image/png' },
      { src: 'https://dummyimage.com/512x512', sizes: '512x512', type: 'image/png' },
    ]
  });

  navigator.mediaSession.setActionHandler('play', function() { /* Code excerpted. */ });
  navigator.mediaSession.setActionHandler('pause', function() { /* Code excerpted. */ });
  navigator.mediaSession.setActionHandler('stop', function() { /* Code excerpted. */ });
  navigator.mediaSession.setActionHandler('seekbackward', function() { /* Code excerpted. */ });
  navigator.mediaSession.setActionHandler('seekforward', function() { /* Code excerpted. */ });
  navigator.mediaSession.setActionHandler('seekto', function() { /* Code excerpted. */ });
  navigator.mediaSession.setActionHandler('previoustrack', function() { /* Code excerpted. */ });
  navigator.mediaSession.setActionHandler('nexttrack', function() { /* Code excerpted. */ });
  navigator.mediaSession.setActionHandler('skipad', function() { /* Code excerpted. */ });


}

export default {
  
  namespaced: true,

  state () {
    return {

      sources: {
        broadcast: null,
        stream: null,
      },

      meta: {
        show: null,
      },

      sourceType: null,

      hasStream: null,
      hasBroadcast: null,

      error: null,

      isLoading: false,
      isPlaying: false,

      seek: null,
      duration: 1,
      position: 0,

      URL: null,

      audioElement: null,

      areAudioEventListenersCreated: false,

    }
  },
  getters(){
    
  },
  mutations: {

    // Activate Broadcast or Stream
    //
    // There can only be a Broadcast or a Stream -- not both.
    //
    activateBroadcast: (state, payload) => {

      // Meta
      state.sourceType = 'audio'
      // state.sourceType = 'video'
      
      // Sources
      state.sources.stream = null
      state.sources.broadcast = payload
      
      // Boolean shortcuts
      state.hasStream = false
      state.hasBroadcast = true
      
      // URL shortcut.
      state.URL = payload.audio.url
      
      // Default to playing from the beginning.
      state.position = 0



    },
    activateStream: (state, payload) => {

      if(!payload){
        // throw Error('Creek: Could not load streams from Creek Studio API.')
        return
      }

      // Meta
      state.sourceType = 'audio'

      // Video would be here:
      // state.sourceType = 'video'

      // Sources
      state.sources.stream = payload
      state.sources.broadcast = null
      
      // Boolean shortcuts
      state.hasStream = true
      state.hasBroadcast = false

      // URL shortcut.
      state.URL = payload.url
      
      // Streams have no position.
      state.position = null

    },

    // Play and Pause
    playBroadcast: (state, payload) => {
      state.isPlaying = true
    },
    pauseBroadcast: (state) => {
      state.isPlaying = false
    },
    playStream: (state, payload) => {
      state.isPlaying = true
    },
    pauseStream: (state) => {
      state.isPlaying = false
    },

    // Play and Pause
    onPlay: (state, payload) => {
      state.isPlaying = true
    },
    onPause: (state) => {
      state.isPlaying = false
    },

    // Handle Loading Status
    onLoadStart: (state) => {
      state.isLoading = true
    },
    onAbort: (state) => {
      state.isLoading = true
    },
    onLoadSuccess: (state, payload) => {
      
      state.isLoading = false
      
      if(payload){
        state.duration = payload.duration
      }

      state.error = null

    },
    onLoadFailure: (state, payload) => {
      state.error = payload
    },

    // File Playback Position
    setPosition: (state, payload) => {
      if(state.sources.broadcast){
        state.position = Math.floor(payload.currentTime)
      }
    },
    onSeek: (state, payload) => {
      state.seek = payload
      X(payload)
      state.isPlaying = true
    },
    onSlidingStart: (state) => {
      state.isPlaying = false
    },

    onPlaybackComplete: (state) => {
      state.isPlaying = false
    },

    clearError: (state) => {
      state.error = null
    },

    setAudioEventListenersCreated: (state) => {

      state.areAudioEventListenersCreated = true

    },

  },

  actions: {

    play({commit}){

      if(!audioElement || !audioElement.play){
        X('noooooooooooo')
        return false
      }
      
      X('wooooooo')

      audioElement.play()

      commit('onLoadStart')

    },

    pause({commit}){

      if(!audioElement || !audioElement.pause){
        return false
      }
      
      audioElement.pause()

    },

    async playBroadcast({dispatch, commit, state}, broadcast){

      commit('activateBroadcast', broadcast)

      try{
        dispatch('loadActivatedSource')
      }catch(error){
        X(error)
      }

      X('wib wob')

      dispatch('play')

    },

    async playStream({dispatch, commit, state, rootState}, stream = null){

      X('woop')

      if(!stream){
        stream = rootState.creek.studio.streamPrimary
      }

      X(stream)

      commit('activateStream', stream)

      try{
        dispatch('loadActivatedSource')
      }catch(error){
        X(error)
      }

      X('wib wob')

      dispatch('play')

    },

    async loadActivatedSource({commit, state}){

      // if(HowlerInstance){
      //   HowlerInstance.unload()
      // }

      if(!audioElement){
        return false
        X('audioElement not loaded')
      }

      X('loading audio url:')
      X(state.URL)

      let sourceURL = state.URL

      // if(state.hasStream){
      //   sourceURL = `${sourceURL}?no-cache=${Math.random()}`
      //   X('adding no-cache query string:')
      //   X(sourceURL)
      // }

      if(!audioElement.src != sourceURL){
        audioElement.src = sourceURL
      }

      /*
      -----------------------------------------------------------
        Handle Audio Events
      -----------------------------------------------------------
      */
      
      if(!state.areAudioEventListenersCreated){

        commit('setAudioEventListenersCreated')

        audioElement.addEventListener('loadeddata', event => {
          X('audio event: loadeddata')
          // X(event)
        })

        audioElement.addEventListener('loadedmetadata', event => {
          X('audio event: loadeddata')
          // X(event)
        })

        audioElement.addEventListener('canplay', event => {
          X('audio event: canplay')
          // X(event)
          // commit('onLoadSuccess')
          commit('onLoadSuccess')
        })
        
        audioElement.addEventListener('canplaythrough', event => {
          X('audio event: canplaythrough')
          // X(event)
        })

        audioElement.addEventListener('durationchange', event => {
          X('audio event: durationchange')
          // X(event)
        })

        audioElement.addEventListener('play', event => {
          X('audio event: play')
          // X(event)
          commit('onPlay')
        })
        
        audioElement.addEventListener('pause', event => {
          X('audio event: pause')
          // X(event)
          commit('onPause')
          // isPlaying = false
        })

        audioElement.addEventListener('seeked', event => {
          X('audio event: seeked')
          // X(event)
        })

        audioElement.addEventListener('seeking', event => {
          X('audio event: seeking')
          // X(event)
        })

        audioElement.addEventListener('stalled', event => {
          X('audio event: stalled')
          // X(event)
          
        })

        audioElement.addEventListener('error', event => {
          X('audio event: error')
          commit('onLoadFailure', event)
          X(event)
          // isPlaying = false
        })

        audioElement.addEventListener('abort', event => {
          X('audio event: abort')
          // X(event)
          // isPlaying = false
          commit('onAbort')
        })

        audioElement.addEventListener('suspend', event => {
          X('audio event: suspend')
          // X(event)
          // isPlaying = false
        })

        audioElement.addEventListener('timeupdate', event => {
          // X('audio event: timeupdate')
          // X(event)
        })

        audioElement.addEventListener('volumechange', event => {
          X('audio event: volumechange')
          // X(event)
        })

        audioElement.addEventListener('waiting', event => {
          X('audio event: waiting')
          commit('onLoadStart')
          // X(event)
          // isPlaying = false
        })

        audioElement.addEventListener('ended', event => {
          X('audio event: ended')
          // X(event)
          // isPlaying = false
          commit('onPlaybackComplete')
        })
        
        // TODO: When is this used?
        audioElement.addEventListener('playing', event => {
          // X('audio event: playing')
          // isPlaying = true
          // X(event)

          // TODO: Is this the right thing to do with 'playing'?
          commit('onPlay')
        })
        
        // "
        audioElement.addEventListener('complete', event => {
          X('audio event: complete')
          // commit('onPlaybackComplete')
          // X(event)
          // isPlaying = false
        })

      }

      return


      Howler.autoUnlock = false;

      HowlerInstance = new Howl({
        src: [sourceURL],
        html5: true,
      })


      HowlerInstance.autoUnlock

      
      X(HowlerInstance)
      
      // Fires when the sound finishes playing.
      HowlerInstance.once('play', () => {
        commit('onPlay')
      })

      // Fires when the sound finishes playing.
      HowlerInstance.once('pause', () => {
        commit('onPause')
      })

      // Fires when the sound finishes playing.
      HowlerInstance.once('load', () => {

        X('Did commit() pass through? Let\'s see...')
        X(commit ? 'Yes! Here it is:' : 'No, it\'s not here:')
        X(commit)

        commit('onLoadSuccess')

      })

      HowlerInstance.on('loaderror', (error) => {
        commit('onLoadFailure', error)
      })

      HowlerInstance.on('end', () => {

        commit('onPlaybackComplete')

        /*
          
          TODO:

          Handle end of broadcast. 
          
          - Go to next broadcast? 
          - Display more broadcast suggestions? 
          - Display ending screen? 
            - YouTube Ending screen: Copy what YouTube does?

        */

      })

    },

    seek({commit}){

      X('seek')
      X(commit.payload)

      return

      HowlerInstance.seek(commit.payload)

    }

  }


}
