<template>
  <div 
    class="creek-schedule"
    :class="{
      'has-find-active': isFindActive
    }"
  >
    <div class="schedule-header">
      <div class="schedule-toggle">
        <button 
          @click="toggleView('schedule')"
          class="schedule-toggle-schedule"
          :class="{ 'creek-selected': selectedView == 'schedule' }"
        >Schedule</button>
        <!--
        <button 
          @click="toggleView('explore')" 
          class="schedule-toggle-explore"
          :class="{ 'creek-selected': selectedView == 'explore' }"
        >Explore All</button>
        -->
        <button 
          @click="toggleView('calendar')" 
          class="schedule-toggle-calendar"
          :class="{ 'creek-selected': selectedView == 'calendar' }"
        >Calendar</button>
      </div>
      <CreekFindTool
        v-if="includeFind"
      />
    </div>
    <div v-if="selectedView == 'schedule'">
      <CreekScheduleNav
        v-if="includeWeekdays"
      />
      <div 
        v-if="isScheduleReadyToRender" 
        class="creek-occurrences"
      >
        <CreekScheduleOccurrence
          :occurrence="occurrence" 
          v-for="(occurrence, occurrenceIndex) in occurrencesFiltered" 
          :key="occurrenceIndex + ':' + occurrence.time.id"
          :shouldDisplayWeekday="stringToFind.length > 1"
        />
      </div>
      <div 
        v-if="!isScheduleReadyToRender" 
        class="creek-occurrences" 
      >
        <span v-text="lang.loading"></span>
      </div>
    </div>
    <div v-if="selectedView == 'calendar'">
      <CreekCalendar :occurrences="occurrencesWithinThisWeek" />
    </div>
  </div>
</template>

<script type="text/javascript">

import _orderBy from 'lodash.orderby'
import lang from '@creek-website-toolkit/lang/eng'

import { 
  getISODay, 
  isBefore, 
  isAfter, 
} from 'date-fns'

import CreekFindTool from '@creek-website-toolkit/components/tools/FindTool'
import CreekScheduleOccurrence from '@creek-website-toolkit/components/schedule/ScheduleOccurrence'
import CreekScheduleNav from '@creek-website-toolkit/components/schedule/ScheduleNav'
import CreekCalendar from '@creek-website-toolkit/components/calendar/Calendar'

import schedule from '@creek-website-toolkit/lib/schedule.js'

export default {

  name: 'CreekSchedule',

  props: {
    includeFind: {
      type: Boolean,
      default: true,
    },
    includeWeekdays: {
      type: Boolean,
      default: true,
    },
    showsPath: {
      type: String,
      default: '/shows'
    },
    authorsPath: {
      type: String,
      default: '/profiles'
    },

    // Move early shows that happen before this cutoff time to end of day.
    startHour: {
      type: Number,
      default: 0
    },

    // First view when displaying schedule.
    initialView: {
      type: String,
      default: 'schedule'
    },

    printableUrl: {
      type: String,
      default: 'schedule'
    },

  },

  components: {
    CreekScheduleOccurrence,
    CreekScheduleNav,
    CreekFindTool,
    CreekCalendar,
  },

  data () {

    return {

      times: null,

      occurrencesWithinThisWeek: [],

      isScheduleReadyToRender: false,

      lang,

      refreshInterval: null,

      refreshCounter: 0,

    }

  },

  computed: {
    selectedView(){
      return this.$store.state.creek.studio.scheduleViewSelected
    },
    weekdayNumberOrToday (){
      
      // if(this.$route && this.$route.query.weekday) {
      //   num = this.$route.query.weekday;
      // }

      // Use state in Vuex if available.
      if(this.$store.state.creek.studio.scheduleWeekdayNumber !== null){
        return this.$store.state.creek.studio.scheduleWeekdayNumber
      }

      // If searching for a show, then ignore weekday.
      if(this.stringToFind){
        return null
      }


      // Default to today.
      let todayNumber = schedule.getISOWeekdayNumberForToday()
      
      
      // X('weekday number:')
      // X(todayNumber)
      
      // Set state to today.
      this.$store.commit('creek/studio/setScheduleWeekdayNumber', todayNumber)

      return todayNumber
    },

    occurrencesFiltered () {
      
      let refreshCounter = this.refreshCounter

      console.log('refreshCounter: ' + refreshCounter)

      let occurrencesFiltered = []

      if(!this.occurrencesWithinThisWeek) {
        return []
      }
      
      // X(this.occurrencesWithinThisWeek)

      for(let o in this.occurrencesWithinThisWeek){
        
        const occurrence = this.occurrencesWithinThisWeek[o]
        let shouldInclude = false


        // Show all for weekday if displaying weekday.
        if(
          occurrence.localWeekdayNum == this.weekdayNumberOrToday
        ){
          shouldInclude = true
        }

        // If using Find:
        if(
          this.stringToFind
          && this.stringToFind != ''
          && this.stringToFind.length > 1
        ){
          // X('finding string: ' + this.stringToFind)
          
          // Include by default.
          shouldInclude = true

          // Exclude if string not found.
          if(
            occurrence.time.show 
            && !occurrence.time.show.title
              .toLowerCase().includes(this.stringToFind.toLowerCase())
          ){
            shouldInclude = false
          }

        }

        // If included, then do some formatting.
        if(shouldInclude){

          // Check if occurrence is current. (Verbose for readability.)
          if(
            isAfter(new Date(), new Date(occurrence.startDate)) 
            && isBefore(new Date(), new Date(occurrence.endDate))
          ){
            occurrence.isCurrent = true
          }else{
            occurrence.isCurrent = false
          }

        }

        // Finally, push to the filtered occurrences if found.
        if(shouldInclude){
          occurrencesFiltered.push(occurrence)
        }

      }

      // Sort by start time.
      occurrencesFiltered = _orderBy(
        occurrencesFiltered, ['startDate'], ['asc']
      )

      return occurrencesFiltered
    },

    stringToFind: {
      get () {
        return this.$store.state.creek.studio.showsStringToFind
      },
      set (value) {
        this.$store.commit('creek/studio/setShowsStringToFind', value)
      }
    },
    timezone(){
      const tz = this.$store.state.creek.studio.studio.timezone
      X(tz)
      return tz 
    },
    isFindActive(){
      if(this.$store.state.creek.studio.showsStringToFind){
        return true
      }
      return false
    }
  },
  methods: {

    async fetchData () {
      
      const studioName = this.$store.state.creek.studio.studioName

      const url = `http://${studioName}.embed.creek.org/api/studio/schedule`
      // const url = `http://${studioName}.dev-embed.creek.org/api/studio/schedule`

      let response = await fetch(url)

      this.occurrencesWithinThisWeek = await response.json()
      
      X(this.occurrencesWithinThisWeek)

    },

    toggleView(viewName){
      this.$store.commit('creek/studio/selectScheduleView', viewName)
    },

    refreshOccurrences(){
      this.refreshCounter++
    }

  },
  async created () {
    
    X('schedule!')

    // Select initial view.

    await this.fetchData()

    // Add paths to the Vuex store.
    this.$store.commit('creek/studio/setShowsPath', this.showsPath)
    this.$store.commit('creek/studio/setAuthorsPath', this.authorsPath)

    this.isScheduleReadyToRender = true

    X('dogs!')

    this.refreshInterval = setInterval(() => {
      this.refreshOccurrences()
    }, 10000)

  },
  destroyed(){
    clearInterval(this.refreshInterval)
  }
}

</script>


<style lang="sass">

@import "/creek/ws/embed/sass/woop.sass"

</style>
