<template>
  <div class="creek-calendar-tool">
    <!-- <span>spacer----------------------------------------------------</span> -->
    <button 
      class="creek-calendar-button"
      v-text="currentDateFormatted"
      @click="toggleCalendar"
      ref="creekCalendarButton"
    />
    <Teleport to="body">
      <div 
        class="creek-calendar" 
        v-if="isCalendarVisible"
        :style="calendarStyle"
      >
        <div class="creek-month-selector">
          <button @click="moveOneMonthBack" >&larr;</button>
          <div 
            class="creek-current-month" 
            v-text="currentMonthFormatted"
          ></div>
          <button @click="moveOneMonthForward" >&rarr;</button>
        </div>
        <div class="creek-days">
          <div 
            class="creek-day creek-day-placeholder"
            v-for="placeholder in placeholderDaysInCurrentMonth"
          ></div>
          <button 
            class="creek-day creek-day-button" 
            v-for="day in daysInCurrentMonth" 
            :key="day.n"
            v-text="day.n"
            @click="selectDate(day.date)"
            :class="{ 
              'creek-day-selected': isDaySelected(day),
              'creek-day-available': isDayAvailable(day),
            }"
          />
            <!-- v-if="dayDate" -->
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script type="text/javascript">

import { 
  getDaysInMonth, 
  startOfMonth,
  addDays,
  addMonths,
  subMonths,
  getDay,
  format,
  startOfDay,
  isEqual,
  parseISO,
} from 'date-fns'

export default {
  name: 'CreekCalendarTool',
  props: {
    initialDate: {
      type: Date
    },
    datesAvailable: {
      type: Array,
      default: [],
    },
    useLatestAvailableDate: {
      type: Boolean
    }
  },
  data () {
    return {
      currentDate: null,
      currentMonthDate: null,
      isCalendarVisible: false,
      // isCalendarVisible: true,
      calendarPosition: {
        x: 0,
        y: 0,
      },
      calendarWidth: 350,
      calendarStyle: null
    }
  },
  computed: {
    daysInCurrentMonth(){
      const daysInMonthInteger = getDaysInMonth(this.currentMonthDate)
      const firstDayInMonthDate = startOfMonth(this.currentMonthDate)
      let daysArray = []
      let dayDate = null

      // console.log(firstDayInMonthDate)

      daysArray.push({
        n: 1,
        date: firstDayInMonthDate,
      })

      for (let d = 2; d <= daysInMonthInteger; d++) {
        dayDate = addDays(firstDayInMonthDate, d - 1)
        daysArray.push({
          n: d,
          date: dayDate,
        })
      }

      return daysArray
    },
    placeholderDaysInCurrentMonth(){
      const firstWeekdayIndex = getDay(startOfMonth(this.currentMonthDate))
      let placeholdersArray = []
      
      // console.log(firstWeekdayIndex)

      for (let d = 0; d < firstWeekdayIndex; d++) {
        placeholdersArray.push(d)
      }

      return placeholdersArray
    },
    firstWeekdayIndex() {
      return getDay(startOfMonth(this.currentDate))
    },
    currentMonthFormatted(){
      return format(this.currentMonthDate, 'LLLL Y')
    },
    currentDateFormatted(){
      return format(this.currentDate, 'LLLL d, Y')
    },
    datesAvailableConverted(){
      let dates = []
      for(let date of this.datesAvailable){
        dates.push(parseISO(date))
      }
      return dates
    }
  },
  methods: {
    moveOneMonthBack(){
      this.currentMonthDate = subMonths(this.currentMonthDate, 1)
    },
    moveOneMonthForward(){
      this.currentMonthDate = addMonths(this.currentMonthDate, 1)
    },
    toggleCalendar(){
      if(!this.isCalendarVisible){
        this.updateCalendarStyle()
      }
      this.isCalendarVisible = !this.isCalendarVisible
      this.updateCalendarStyle()
    },
    closeCalendar(){
      this.isCalendarVisible = false
    },
    selectDate(date){
      this.currentDate = date
      this.closeCalendar()
      this.$emit('dateSelected', date)
    },
    isDaySelected(day){
      return isEqual(
        startOfDay(this.currentDate), startOfDay(day.date)
      )
    },
    isDayAvailable(day){
      // console.log(this.datesAvailableConverted)
      for(let date of this.datesAvailableConverted){
        // console.log(date)
        // console.log(day.date)
        if(isEqual(date, day.date)){
          return true
        }
      }
      return false
    },
    selectLatestAvailableDateIfNeeded(){
      if(
        this.datesAvailable.length == 0 
        || this.datesAvailableConverted.length == 0
      ){
        return
      }
      this.selectDate(
        this.datesAvailableConverted[
          this.datesAvailableConverted.length - 1
        ]
      )
    },
    getWindowDimensions(){
      const dimensions = {
        width: Math.max(
          document.documentElement.clientWidth || 0, window.innerWidth || 0
        ),
        height: Math.max(
          document.documentElement.clientHeight || 0, window.innerHeight || 0
        ),
      }
      return dimensions
    },
    getElementPosition(element) {
      var box = element.getBoundingClientRect();

      var body = document.body;
      var docEl = document.documentElement;

      var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

      var clientTop = docEl.clientTop || body.clientTop || 0;
      var clientLeft = docEl.clientLeft || body.clientLeft || 0;

      var top  = box.top +  scrollTop - clientTop;
      var bottom = box.bottom + scrollTop - clientTop;
      var left = box.left + scrollLeft - clientLeft;
      var right = box.right + scrollLeft - clientLeft;

      // return { 
      //   top: Math.round(top), 
      //   left: Math.round(left),
      //   bottom: Math.round(bottom),
      //   right: Math.round(right),
      // };

      return { 
        top, 
        left,
        bottom,
        right,
      };
    },
    getCalendarPosition(){
      
      if(!this.$refs.creekCalendarButton){
        return null
      }
      
      const windowDimensions = this.getWindowDimensions()
      
      // let position = {
      //   x: this.$refs.creekCalendarButton.getBoundingClientRect().left,
      //   y: this.$refs.creekCalendarButton.getBoundingClientRect().bottom
      // }

      let buttonPosition = this.getElementPosition(this.$refs.creekCalendarButton)

      let position = {
        x: buttonPosition.left,
        y: buttonPosition.bottom,
      }

      // console.log(position)

      // Shift left if outside of screen
      if(windowDimensions.width < (position.x + this.calendarWidth)){
        const outsideX = windowDimensions.width 
          - (position.x + this.calendarWidth)
        position.x = position.x + outsideX
      }

      position.y += 2
      // position.x -= 5

      if(windowDimensions.width < this.calendarWidth){
        position.x = 0
      }

      return position
    },
    updateCalendarStyle(){
      
      const position = this.getCalendarPosition()

      if(!position){
        return
      }

      let style = {
        top: position.y + 'px',
        left:  position.x + 'px',
        width: this.calendarWidth + 'px',
      }

      this.calendarStyle = style

      return style
    },
    handleWindowResized(){
      this.updateCalendarStyle()
    }
  },
  created () {
    
    this.currentDate = startOfDay(this.initialDate)
    this.currentMonthDate = startOfDay(this.initialDate)

    this.updateCalendarStyle()

    window.addEventListener("resize", this.handleWindowResized)
    
    this.selectLatestAvailableDateIfNeeded()

  },
  destroyed() {
    window.removeEventListener("resize", this.handleWindowResized)
  },
}

</script>
<style lang="sass" scoped>

.creek-calendar
  max-width: 100%
  // width: 300px
  height: 250px
  padding: 10px
  border: 2px solid #ddd
  border-radius: 6px
  font-family: Arial, Helvetica, sans-serif
  position: absolute
  top: 0
  left: 0
  background: #fff
  box-sizing: border-box

.creek-month-selector
  display: flex
  height: 16.66%
  box-sizing: border-box
  padding: 4px 0
  align-items: center
  justify-content: center

  button
    flex: 1
    padding: 6px
    border: 1px solid #999
    border-radius: 4px
    background: #fff

    &:hover
      background: #eee
      cursor: pointer

  .creek-current-month
    flex: 3
    text-align: center
    // background: #999

.creek-days
  display: flex
  flex-wrap: wrap
  height: 83.33%

.creek-day  
  width: 14.26%
  // height: 16.66%
  box-sizing: border-box

  &.creek-day-button
    border: 0
    // border: 1px solid #999
    background: #fff
    font-size: 16px
    // border-radius: 4px

    &.creek-day-available
      background: #adf
      // border: 1px solid rgba(0,0,0,0.1)

      &:hover
        background: #7e7
  
    &.creek-day-selected
      background: #7e7
      
      &:hover
        background: #7e7

    &:hover
      background: #cef
      cursor: pointer

  &.creek-day-placeholder
    // border: 1px solid #ccc
    // background: #eee

</style>