
import { 
  sub, 
  lightFormat, 
  // format as formatDate, 
  parseISO 
} from 'date-fns'

const format = (date) => {
  
  // X(date)
  
  if(!date){
    return ''
  }

  let parsed = null
  let formatted = null

  try{

    // X(typeof date)
    // X(date instanceof Date)

    if(!(date instanceof Date)){
      // X('not a date!')
      parsed = parseISO(date)
    }else{
      // X('is a date')
      parsed = date
    }

  }catch(error){
    X(error)
  } 

  try{

    // // HACK: Subtract 5 weeks.
    // const parsedHack1 = sub(parsed, { years: 1 })

    // X(parsedHack1)

    // formatted = formatDate(parsed, 'K:mmaa')
    formatted = lightFormat(parsed, 'h:mma')

  }catch(error){
    X('Could not parse date:')
    X(error)
  } 

  return formatted
}


const formatDate = (date) => {
  
  // X(date)
  
  if(!date){
    return ''
  }

  let parsed = null
  let formatted = null

  try{

    // X(typeof date)
    // X(date instanceof Date)

    if(!(date instanceof Date)){
      // X('not a date!')
      parsed = parseISO(date)
    }else{
      // X('is a date')
      parsed = date
    }

  }catch(error){
    X(error)
  } 

  try{

    // // HACK: Subtract 5 weeks.
    // const parsedHack1 = sub(parsed, { years: 1 })

    // X(parsedHack1)

    // formatted = formatDate(parsed, 'K:mmaa')
    formatted = lightFormat(parsed, 'M/d/yyyy')

  }catch(error){
    X('Could not parse date:')
    X(error)
  } 

  return formatted
}

export default {

  format,
  formatDate

}
