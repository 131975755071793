<template>
  <div 
    class="creek-show" 
    :class="['creek-show-' + show.name]"
    @click="navigateTo(getShowPath(show))"
  >
    <div class="grouped-show-label" v-if="isFromGroup">
      [is-from-group]
      <!--  -->
    </div>
    <div class="creek-show-inner">
      <div class="creek-image-container" v-if="show.image">
        <img 
          :src="show.image.url" 
          @click="navigateTo(getShowPath(show))" 
        />
      </div>
      <div class="creek-info-container">
        <a 
          class="creek-show-title" 
          v-text="show.title"
          @click="navigateTo(getShowPath(show))" 
        ></a>
        <div 
          class="creek-summary" 
          v-text="show.summary"
        ></div>
        <!--
        <div class="creek-authors-container">
          <ul>
            <li v-for="author in show.authors">
              <a 
                class="creek-author-link" 
                href="#" 
                :href="navigateTo(getAuthorPath(author))" 
                v-text="author.display_name"
              ></a>
            </li>
          </ul>
        </div>
        -->
        <!--
        <div class="tags">
          <div class="tags-container">
            <ul>
              <li v-for="tag in show.tags">
                <a href="#" class="creek-category-link" v-text="tag.title"></a>
              </li>
            </ul>
          </div>
        </div>
        -->
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">

import { lightFormat } from 'date-fns'

import navigate from '@creek-website-toolkit/lib/navigate.js'

export default {
  name: 'CreekShow',
  props: {
    show: {
      type: Object
    },
    startDate: {
      type: Date
    },
    endDate: {
      type: Date
    },
    isFromGroup: {
      type: Boolean
    }
  },
  data () { 
    return {
    } 
  },
  computed: {
  },
  methods: {
    getShowPath(show){
      return this.$store.state.creek.studio.showsPath + '/' + show.name
    },
    getAuthorPath(author){
      return this.$store.state.creek.studio.authorPath + '/' + author.name
    },
    navigateTo: navigate.to,
  },
  created() {

    // console.log(this.dataShow);

  }
}

</script>