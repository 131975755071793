import { createStore } from 'vuex'

import MainModule from '@creek-website-toolkit/store/MainModule'

const createStoreWithStudioModule = () => {

  const store = createStore({
    modules: {
      creek: MainModule
    }
  })
  
  return store

}

export default createStoreWithStudioModule
