<template>
  <div 
    class="creek-schedule-occurrence"
    :class="{ 
      'is-current': occurrence.isCurrent, 
      'has-special-rule': occurrence.specialRule,
    }"
    @click="navigateTo(getShowPath(occurrence.time.show))" 
  >
    <div v-if="occurrence.isCurrent" class="is-current-label">Live</div>
    <div 
      v-if="occurrence.startDate && occurrence.endDate"
      class="creek-time-container"
    >
      <div class="creek-time">
        <span 
          class="creek-weekday" 
          v-if="shouldDisplayWeekday"
          v-text="formatWeekday(occurrence.startDate)"
        ></span>
        <span 
          class="creek-start" 
          v-text="formatTime(occurrence.startDate)"
        ></span>
        <span class="creek-divider">&mdash;</span>
        <span 
          class="creek-end" 
          v-text="formatTime(occurrence.endDate)"
        ></span>
        <!--<textarea style="height: 100px;" v-text="occurrence.time.rrule"></textarea>-->
      </div>

      <span class="special-rule" v-text="occurrence.specialRule"></span>
    </div>
    <CreekShowCard 
      :show="occurrence.time.show" 
    />
    <!-- 
    <CreekShow 
      v-for="(occurrence, index) in occurrence.group"
      :is-from-group="true"
      :show="occurrence.time.show" 
      :start-date="occurrence.startDate"
      :end-date="occurrence.endDate"
      :key="index"
    /> 
    -->
    <div 
      class="creek-rrule-container"
      v-if="occurrence.specialRule" 
    >
      <div class="nth-shows" v-if="occurrence.nthTimeGroup">
        <div v-for="n in occurrence.nthTimeGroup">
          <span class="ordinal" v-text="getOrdinalFromNth(n.nth)"></span>
          <span class="weekday" v-text="getPluralWeekday(n.weekday)"></span>
          <a @click="" href=""></a>
        </div>
      </div>
      <div class="alternating-shows" v-if="occurrence.timeGroup">
        <span class="next-week-title">Next Week</span>
        <div class="alternating-show" v-for="time in occurrence.timeGroup">
          <a 
            @click="navigateTo(getShowPath(time.show))"
            class="show-title" 
            v-text="time.show.title"
          ></a>
        </div>
      </div>
      <div 
        v-if="occurrence.nextWeek" 
        class="next-week" 
        v-text="occurrence.nextWeek.show.title"
      ></div>
    </div>
  </div>
</template>

<script>

import CreekShowCard from '@creek-website-toolkit/components/cards/ShowCard';

import time from '@creek-website-toolkit/lib/time.js'
import schedule from '@creek-website-toolkit/lib/schedule.js'
import navigate from '@creek-website-toolkit/lib/navigate.js'

import { getISODay } from 'date-fns'

export default {
  name: 'CreekScheduleOccurrence',
  props: {
    occurrence: {
      type: Object
    },
    shouldDisplayWeekday: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {}
  },
  components: {
    CreekShowCard
  },
  methods: {
    formatTime: time.format,
    formatWeekday(date){ 
      // X('weekday')
      // X(typeof date)
      // X(typeof new Date(date))
      return schedule.getISOWeekdayFromDate(new Date(date))
    },
    getShowPath(show){
      return this.$store.state.creek.studio.showsPath + '/' + show.name
    },
    navigateTo: navigate.to,
  }
}
</script>