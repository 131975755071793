<template>
  <div class="creek-schedule-element">
    <CreekSchedule
      :include-find="includeFind"
      :shows-path="showsPath"
      :authors-path="authorsPath"
    ></CreekSchedule>
    <link rel="stylesheet" href="http://woop.creek.org/css/woop.css" />
    <!--
    -->
  </div>
</template>

<script>

import CreekSchedule from '@creek-website-toolkit/components/schedule/Schedule.vue'

export default {
  name: 'CreekScheduleElement',
  components: {
    CreekSchedule
  },
  props: {
    studioName: {
      type: String,
      default: null
    },
    includeFind: {
      type: String,
      default: true
    },
    showsPath: {
      type: String,
      default: true
    },
    authorsPath: {
      type: String,
      default: true
    },
  },
  async created(){
    
    console.log('loaded CreekScheduleElement')

    // X(this.studioName)
    
    let studioName = this.studioName

    if(!studioName && window.$Creek.studioName){
      studioName = window.$Creek.studioName
    }
    
    if(!studioName){
      throw new Error('Creek: No studio-name specified.')
    }

    // Set the studio domain since it's required.
    await this.$store.dispatch(
      'creek/studio/init', studioName + '.dev-studio.creek.org'
    )

    console.log('Creek: Schedule Element: Loading: ' + studioName)

  }
  // styles: ['div.hello { background: #f00 }']
}
</script>


<!--
<style>
  @import '<%= BASE_URL %>/style/woop.css';
</style>
-->

<style lang="sass">

@import "@creek-website-toolkit/sass/schedule.sass"

</style>
