<template>
  <div class="creek-playlists-panel">
    <div 
      v-if="toolbarEnabled" 
      class="options-toolbar" 
    >
      <div 
        v-if="!showName" 
        class="selector-container show-container"
      >
        <label for="show-selector">Show</label>
        <select 
          v-model="showSelected" 
          v-on:change="selectShow($event.target.value, {clearDate: true})"
        >
          <option value="">All Shows</option>
          <option 
            id="show-selector" 
            v-for="show in shows" 
            :value="show.id" 
            v-text="show.title"
            v-bind:key="show.id"
          ></option>
        </select>
      </div>
      <div class="selector-container date-container">
        <label for="date-selector">Date</label>
        <div class="datepicker-container">
          <CreekCalendarTool 
            :initial-date="dateSelected" 
            :dates-available="datesAvailable"
            @date-selected="selectDate"
          />
        </div>
      </div>
    </div>
    <div 
      class="playlist-loading" 
      v-if="isPlaylistsLoading || isBroadcastsLoading"
    >
      Loading...
    </div>
    <div 
      class="broadcasts-container" 
      v-if="usingBroadcasts && isBroadcastsLoaded"
    >
      <div 
        class="broadcast" 
        v-for="broadcast in broadcasts"
      >
        <div class="show">
          <h3 v-text="broadcast.show.title"></h3>
          <div 
            class="date-time" 
            v-text="getBroadcastDateAndTime(broadcast)"
          ></div>
        </div>
        <div 
          class="track-outer-row"
          v-for="track in broadcast.playlist"
        >
          <CreekTrack 
            :track="track"
          />
        </div>
      </div>
    </div>
    <div 
      class="tracks-container" 
      v-if="usingTracks && isPlaylistsLoaded"
    >
      <transition-group 
        name="fade" 
        tag="div" 
        mode="out-in"
      >
        <div 
          v-for="(track, trackIndex) in tracks" 
          v-bind:key="track.id" 
          mode="out-in"
          class="track-outer-row"
        >
          <div class="show" v-if="shouldDisplayBroadcastHeading(track, trackIndex)">
            <h3 v-text="getShowById(track.show_id).title"></h3>
            <div class="broadcast" v-if="track.broadcast">
              <div 
                class="date-time" 
                v-text="getBroadcastDateAndTime(track.broadcast)"
              ></div>
            </div>
          </div>
          <CreekTrack 
            :track="track"
          />
          <!-- :alwaysShowExtra="true" -->
          <div 
            class="playlist-empty"
            v-if="isPlaylistsLoaded && tracks.length == 0"
          >
            No playlists found.
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import { format, parse } from 'fecha'
import CreekCalendarTool from '/creek/ws/studio-website-toolkit/components/tools/CalendarTool.vue';
import CreekTrack from '/creek/ws/studio-website-toolkit/components/fragments/Track.vue';

export default {
  name: 'PlaylistsPanel',
  props: {
    studioName: {
      type: String,
      default: null,
    },
    showName: {
      type: String,
      default: null,
    },
    toolbarEnabled: {
      type: Boolean,
      default: true,
    },
    useBroadcasts: {
      type: Boolean,
      default: false,
    },
    useTracks: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CreekCalendarTool,
    CreekTrack,
  },
  data(){
    return { 
      
      studio: {},
      show: null,
      tracks: [],
      broadcasts: [],
      shows: [],

      usingBroadcasts: false,
      usingTracks: false,

      isPlaylistsLoading: false,
      isPlaylistsLoaded: false,

      isBroadcastsLoading: false,
      isBroadcastsLoaded: false,

      isShowLoading: false,
      isShowLoaded: false,

      isShowsLoading: false,
      isShowsLoaded: false,


      inputValue: {},
      inputEvents: {},

      // From Archives.vue
      showSelected: '',
      dateSelected: new Date(),
      datesDisabled: {
        // to: new Date(2020, 10, 6),
        // ranges: [{
          // from: new Date(2020, 9, 6)}
        // from: new Date(9999, 1, 1)
      },
      datesAvailable: [
        // '2022-03-16',
        // '2022-03-18',
      ],

      refreshInterval: null,

      selected: {
        showId: null,
      },
      
      pagination: {
        broadcastsList: {
          currentPage: 1
        }
      },

      enableToolbar: true,

    }
  },
  methods: {

    /*
    -----------------------------------------------------------
      From Archives.vue
    -----------------------------------------------------------
    */

    selectShow(showId, options = null){
      
      this.selected.showId = showId

      // Clear the date.
      if(options && options.clearDate){
        this.selectDate(null)
      }

    },

    selectDate(date = null){

      this.selected.date = date
      // const dateFormatted = format(date, 'isoDate')

      // console.log(date)

      this.refresh()

      // Reset show to All Shows.
      // this.selectShow('', { clearDate: false });
      // this.showSelected = ''

    },

    getBroadcastDateAndTime(broadcast){
      
      let title = ''
      let startFormat = 'h:mmA, MMMM Do, YYYY'
      let endFormat = 'h:mmA, MMMM Do, YYYY'

      const startParsed = parse(broadcast.start, 'isoDateTime')
      const endParsed = parse(broadcast.end, 'isoDateTime')

      if(format(startParsed, 'D') == format(endParsed, 'D')){
        startFormat = 'h:mmA'
      }

      title = format(startParsed, startFormat)
        + ' - '
        + format(endParsed, endFormat)

      return title
    },

    formatDate(date = null){
      return format(new Date(date), 'hh:mma, MM/DD/YYYY')
    },

    formatTime(date = null){
      return format(new Date(date), 'hh:mma')
    },

    getShowById(showId){
      return this.shows.filter(show => show.id == showId)[0] || {}
    },

    shouldDisplayBroadcastHeading(track, trackIndex){
      if(
        !this.tracks[trackIndex-1]
        || (
          this.tracks[trackIndex-1]
          && (
            this.tracks[trackIndex-1].show_id != track.show_id
            || this.tracks[trackIndex-1].broadcast_id != track.broadcast_id
          )
          && this.getShowById(track.show_id)
        )
      ){
        return true
      }
      return false
    },

    async getShows(){

      this.isShowsLoading = true

      if(!this.URLPrefix){
        return
      }

      let response = null

      response = await axios.get(this.URLPrefix + '/api/shows')

      this.shows = response.data.data
      
      // console.log(this.shows)

      this.isShowsLoading = false

    },

    async getShow(){

      this.isShowLoading = true

      if(!this.URLPrefix){
        return
      }

      let response = null

      response = await axios.get(
        this.URLPrefix + '/api/shows/' + this.showName
      )

      this.show = response.data.data
      
      // console.log(this.shows)

      this.isShowLoading = false

    },

    async getBroadcastsListForDatepicker(){

      this.isBroadcastsListLoading = true
      this.isBroadcastsListLoaded = false

      if(!this.URLPrefix){
        return
      }

      // this.handleQueryStrings()

      let options = '?'

      // Only use showId if showName is not provided.
      if(this.selected.showId && !this.showName){
        options += '&byShow=' + this.selected.showId
      }
      
      else if(this.showName && this.show){
      // else if(this.showName && this.show){
        // console.log(this.show)
        options += '&byShow=' + this.show.id
      }

      if(this.selected.showId && !this.showName){
        options += '&page=' + this.pagination.broadcastsList.currentPage
        options += '&limit=10'
      }

      let response = null

      response = await axios.get(this.URLPrefix + '/api/interface/broadcasts-dates-for-show' + options)
      
      // console.log(response.data)
      this.broadcastsList = response.data.data

      this.isBroadcastsListLoading = false
      this.isBroadcastsListLoaded = true

    },
    
    async getBroadcasts(){

      this.isBroadcastsLoading = true
      this.isBroadcastsLoaded = false

      if(!this.URLPrefix){
        return
      }

      // this.handleQueryStrings()

      let options = '?'

      options += 'include=playlist'

      options += '&has=playlist'

      options += '&limit=1'

      // Only use showId if showName is not provided.
      if(this.selected.showId && !this.showName){
        options += '&byShow=' + this.selected.showId
      }
      
      else if(this.showName && this.show){
      // else if(this.showName && this.show){
        // console.log(this.show)
        options += '&byShow=' + this.show.id
      }

      if(this.selected.date){
        options += '&toDate=' + encodeURIComponent(
          format(this.selected.date, 'isoDate')
        )
      }

      let response = null

      response = await axios.get(this.URLPrefix + '/api/broadcasts' + options)
      
      // console.log(response.data)
      this.broadcasts = response.data.data

      this.isBroadcastsLoading = false
      this.isBroadcastsLoaded = true

    },

    async getTracks(){

      this.isPlaylistsLoading = true
      this.isPlaylistsLoaded = false

      if(!this.URLPrefix){
        return
      }

      // this.handleQueryStrings()

      let options = '?'

      options += 'include=broadcast'


      // Only use showId if showName is not provided.
      if(this.selected.showId && !this.showName){
        options += '&showId=' + this.selected.showId
      }
      
      else if(this.showName && this.show){
        // console.log(this.show)
        options += '&showId=' + this.show.id
      }

      if(this.selected.date){
        options += '&toDate=' + encodeURIComponent(
          format(this.selected.date, 'isoDate')
        )
      }

      if(this.selected.broadcastId){
        options += '&broadcastId=' + encodeURIComponent(
          this.selected.broadcastId
        )
      }

      let response = null

      response = await axios.get(this.URLPrefix + '/api/tracks' + options)
      
      // console.log(response.data)
      this.tracks = response.data.data

      this.isPlaylistsLoading = false
      this.isPlaylistsLoaded = true

    },

    async refresh() {

      this.getShows()

      if(this.showName && !this.show){
        await this.getShow()
      }

      if(this.usingBroadcasts){
        this.getBroadcasts()
      }
      else{
        this.getTracks()
      }

    },

    getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },

    // handleQueryStrings(){

    //   const showIdParam = this.getParameterByName('showId');
    //   const showNameParam = this.getParameterByName('showName');

    //   if(showIdParam){
    //     this.enableToolbar = false
    //     this.filterArchivesByShowId(showIdParam, false)
    //   }
      
    //   if(showNameParam){
    //     this.enableToolbar = false
    //     this.filterArchivesByShowName(showNameParam, false)
    //   }

    // },

    startRefreshInterval(){

      this.refreshInterval = setInterval(this.refresh, 30000)

    },

  },
  created(){
    
    // console.log(window.location)

    this.usingBroadcasts = true

    if(this.useTracks){
      this.usingBroadcasts = false
    }

    this.URLPrefix = `https://${this.studioName}.studio.creek.org`

    // studioDomain.replace

    startRefreshInterval()

    axios.get(this.URLPrefix + '/api/studio')
      .then((response) => {
        
        this.studio = response.data

        this.refresh()

        // this.startRefreshInterval()

      })
  },
  beforeDestroy(){
    clearInterval(this.refreshInterval)
  }
}

</script>
<style lang="sass">

@import "../../sass/playlists-panel.sass"

</style>